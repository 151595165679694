<template>
  <div class=" min-h-screen flex items-center justify-center flex-col">
    <div class=" font-900 erro_page_text">404</div>
    <div class="font-800 text-5xl">Ooops!!</div>
    <div class=" text-2xl leading-12">THAT PAGE DOESN'T EXIST OR IS UNAVAILABLE</div>
    <input class="  px-8 py-4 rounded-lg text-lg cursor-pointer mt-4 hover:bg-gray-500 duration-300" @click="handleBack" type="button" value="Go Back to Home">
  </div>
</template>

<script>
export default {
  methods: {
    handleBack () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.erro_page_text{
  font-size: 9rem;
}
</style>
